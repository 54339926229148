document.addEventListener("DOMContentLoaded", function() {
    var openers = document.querySelectorAll('.header-nav__opener');

    openers.forEach(function(opener) {
        opener.addEventListener('click', function() {
            var parentLi = this.closest('li');
            parentLi.classList.toggle('submenu-is-open');
        });
    });
});
