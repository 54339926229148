const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children');

menuItemsWithChildren.forEach(item => {
    const link = item.querySelector('a');

    const testDiv = document.createElement('div');
    testDiv.className = 'header-nav__opener hide--desktop';

    link.parentNode.insertBefore(testDiv, link.nextSibling);
});
